<template>
  <div style="background: #F6F6F7;">
    <pcHeaders></pcHeaders>
    <div style="display: flex;justify-content: center;background: #fff;width: 100%;height: 100px;align-items: center;">
      <img style="width: 1000px;height: 80px;"
           src="../../assets/images/lc03.png"
           alt="">
    </div>
    <div class="flex"
         style="justify-content: center;">
      <div class="banners">
        <div class="flex">
          <div>
            <img src="../../assets/indexImg/02_03.png"
                 alt="">
          </div>
          <div style="font-size: 15px;margin-left: 5px;margin-top: 1px;">
            我的购物车
          </div>

        </div>

        <div style="margin-top: 10px;">
          <el-table style="width: 100%"
                    :data="carList">
            <el-table-column label="班级名称"
                             align="center"
                             width="250">
              <template slot-scope="scope">
                <span>{{ scope.row.trainClass.className }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="address"
                             label="专业分类"
                             align="center"
                             width="250">
              <template slot-scope="scope">
                <span>({{ scope.row.trainClass.lx }}) {{ scope.row.trainClass.zy }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="address"
                             label="培训方式"
                             align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.trainClass.classPaytype == '01' ? '在线' : scope.row.trainClass.classPaytype == '02' ? '面授' : '混合'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="totalCredit"
                             label="学时"
                             align="center">
              <!-- <template slot-scope="scope">
                <span >{{ scope.row.className }}</span>
              </template> -->
              <template slot-scope="scope">
                <span>{{ scope.row.totalCredit.toFixed(1) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="totalPrice"
                             label="价格"
                             align="center">
              <template slot-scope="scope">
                <span style="color: #FF7557;">￥{{ scope.row.totalPrice.toFixed(1) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="address"
                             label="学习年度"
                             align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.trainClass.classYear }}</span>
              </template>
            </el-table-column>
            <el-table-column label="报名内容"
                             align="center">
              <template slot-scope="scope">
                <span style="color: #467AC2;cursor: pointer;"
                      @click="showBox(scope.row)">查看</span>
              </template>
            </el-table-column>
            <el-table-column prop="address"
                             label="操作"
                             align="center">
              <template slot-scope="scope">
                <span style="color: #467AC2;cursor: pointer;"
                      @click="delet(scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="btmText">
          <div class="flex"
               style="cursor: pointer;"
               @click="allDelet">
            <div><img src="../../assets/images/del-03_07.png"
                   alt=""></div>
            <div style="margin-left: 5px;font-size: 14px;">清空购物车</div>
          </div>

          <div style="font-size: 15px;">
            您所选班级数量：<span style="color: #EE4638;">{{ carList.length }}</span>
            课程数量：<span style="color: #EE4638;">{{ totalCourse }}</span>
            课程学时：<span style="color: #EE4638;">{{ totalCourseCredit }}</span>
            考试数量：<span style="color: #EE4638;">{{ totalExam }}</span>
            考试学时：<span style="color: #EE4638;">{{ totalExamCredit }}</span>
            报名费用：<span style="color: #EE4638;">{{totalPrice}}元</span>
          </div>
        </div>

        <div class="flex"
             style="justify-content: space-between;margin-top: 30px;">
          <div></div>
          <div class="flex">
            <div class="subtn"
                 style="background: #BCBCBC;"
                 @click="translate">
              继续选课
            </div>
            <div class="subtn"
                 style="margin-left: 26px;"
                 @click="settlement">
              结算
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title=""
               :visible.sync="dialogVisible"
               width="70%">

      <div class="flex"
           style="justify-content: center;"
           v-if="examCartList.length !== 0">
        <div class="banners"
             style="background: #fff;padding: 0;width: 100%;">
          <div class="flex">
            <div>
              <img src="../../assets/indexImg/02_03.png"
                   alt="">
            </div>
            <div style="font-size: 15px;margin-left: 5px;margin-top: 1px;">
              独立考试列表
            </div>

          </div>

          <div style="margin-top: 10px;width: 100%;">
            <el-table style="width: 100%"
                      :data="examCartList">
              <el-table-column label="序号"
                               align="center"
                               width="180">
                <template slot-scope="scope">
                  <span>{{ scope.$index+1 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="考试名称"
                               align="center"
                               width="180">
                <template slot-scope="scope">
                  <span style="color: #4077C0;">{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="CCredithour"
                               label="总分/及格分数"
                               align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.totalPoint }} / {{ scope.row.passscore }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="credit"
                               label="学时"
                               align="center">
              </el-table-column>
              <el-table-column prop="price"
                               label="价格"
                               align="center">
                <template slot-scope="scope">
                  <span style="color: #FF7557;">￥{{ scope.row.price }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="timelimit"
                               label="考试时长"
                               align="center">
                <template slot-scope="scope">
                  <span style="color: #4077C0;">{{ scope.row.timelimit }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="flex"
           style="justify-content: center;margin-top: 10px;">
        <div class="banners"
             style="background: #fff;padding: 0;width: 100%;">
          <div class="flex">
            <div>
              <img src="../../assets/indexImg/02_03.png"
                   alt="">
            </div>
            <div style="font-size: 15px;margin-left: 5px;margin-top: 1px;">
              购买课程
            </div>

          </div>

          <div style="margin-top: 10px;width: 100%;">
            <el-table style="width: 100%"
                      :data="courseCartList">
              <el-table-column label="序号"
                               align="center"
                               width="80">
                <template slot-scope="scope">
                  <span>{{ scope.$index+1 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="课程名称"
                               align="center"
                               width="480">
                <template slot-scope="scope">
                  <span style="color: #4077C0;">{{ scope.row.CName }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="CCredithour"
                               label="学时"
                               align="center">
                <!-- <template slot-scope="scope">
                <span >{{ scope.row.className }}</span>
              </template> -->
              </el-table-column>
              <!-- <el-table-column prop="CPrice"
                               label="价格"
                               align="center">
                <template slot-scope="scope" >
                  <span v-if="tclass.isClassApply == '02'" style="color: #FF7557;">￥{{ scope.row.CPrice }}</span>
                  <span v-else style="color: #FF7557;">￥{{tclass.creditPrice * scope.row.CCredithour }}</span>
                </template>

              </el-table-column> -->
              <el-table-column prop="address"
                               label="操作"
                               align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.isAskHours == '01'"
                        style="color: #467AC2;cursor: pointer;"
                        @click="deletCourse(scope.row)">删除</span>
                  <span v-if="scope.row.isAskHours == '02'"
                        style="color: #467AC2;cursor: pointer;">无</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <pcBottom></pcBottom>
    <flotRight/>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import flotRight from "@/views/component/flotRight.vue";
import { toShoppingCart, doDelShoppingCartByClass, doDelShoppingCart, toCourseCk, doDelShoppingCartByCourse } from '@/api/car.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom,
    flotRight
  },
  data() {
    // 这里存放数据
    return {
      carList: [], //购物车数据
      dialogVisible: false,
      courseCartList: [], //购物车课程列表
      examCartList: [], //独立考试列表
      tclass: {},
      classId: ''
    };
  },
  // 监听属性 类似于data概念
  computed: {
    totalCourse() {
      let total = 0;
      this.carList.forEach(v => {
        total += v.totalCourse;
      });
      return total;
    },
    totalCourseCredit() {
      let total = 0;
      this.carList.forEach(v => {
        total += v.totalCourseCredit;
      });
      return total;
    },
    totalExam() {
      let total = 0;
      this.carList.forEach(v => {
        total += v.totalExam;
      });
      return total;
    },
    totalExamCredit() {
      let total = 0;
      this.carList.forEach(v => {
        total += v.totalExamCredit;
      });
      return total;
    },
    totalPrice() {
      let total = 0;
      this.carList.forEach(v => {
        total += v.totalPrice;
      });
      return total;
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    translate() {
      this.$router.push('/lsitFs');
    },
    //删除课程
    deletCourse(row) {
      console.log(row);
      let data = {
        courseId: row.id
      };
      doDelShoppingCartByCourse(data).then(res => {
        console.log(res.data.data.code);
        if (res.data.data.code == '1') {
            //  this.$message({
            //   message: '删除成功！',
            //   type: 'success'
            // });
          toCourseCk({ classId: this.classId }).then(res => {
            console.log(res.data);
            this.courseCartList = res.data.data.courseCartList;
            this.examCartList = res.data.data.examCartList;
            this.tclass = res.data.data.tclass;
            this.toShoppingCart();
            this.$message({
              message: '删除成功！',
              type: 'success'
            });
          });
        }
      });
    },
    //查看报名内容
    showBox(row) {
      this.classId = row.classId;
      toCourseCk({ classId: row.classId }).then(res => {
        console.log(res.data);
        this.courseCartList = res.data.data.courseCartList;
        this.examCartList = res.data.data.examCartList;
        this.tclass = res.data.data.tclass;
        console.log(this.tclass);
        this.dialogVisible = true;
      });
    },
    //删除
    delet(row) {
      let data = {
        classId: row.classId
      };
      doDelShoppingCartByClass(data).then(res => {
        console.log(res.data.data.code);
        if (res.data.data.code == '1') {
          this.$message({
            message: '删除成功！',
            type: 'success'
          });
          this.toShoppingCart();
        }
      });
    },
    //清空
    allDelet() {
      doDelShoppingCart().then(res => {
        if (res.data.data.code == '1') {
          this.$message({
            message: '清空购物车成功！',
            type: 'success'
          });
          this.toShoppingCart();
        }
      });
    },
    purchase() {},
    settlement() {
      if (this.carList.length == 0) {
        this.$message('您还没选择课程,请先选择课程加入购物车后,再结算!');
        return false;
      }
      let clasIds = [];
      this.carList.forEach(v => {
        clasIds.push(v.classId);
      });
      this.$router.push({
        path: '/orderInformation',
        query: {
          classId: clasIds.toString()
        }
      });
    },
    toShoppingCart() {
      toShoppingCart().then(res => {
        this.carList = res.data.data.sCartList;
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.toShoppingCart();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.flex {
  display: flex;
}
.banners {
  background: #f6f6f7;
  padding: 80px 0 150px 0;
  width: 1200px;
  font-family: Regular;
  .btmText {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #dbdbdb;
  }

  .subtn {
    width: 120px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    border-radius: 3px;
    background: #ff9f53;
  }
}
</style>